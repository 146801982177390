<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "วางบิล",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  // components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,

      title: "แชท",
      items: [
        {
          text: "แชท",
          active: true,
        },
        {
          text: "แชท",
          active: false,
        },
      ],
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      rowsSelect: [],
      loading: false,
      submitform: false,
      selected: "",
      rowsTotalpages: "",
      totalPage: "",
      totalRecord: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      rowsCus: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      billingId: "",
      selectMode: "multi",
      userBranchId: "",

      billingDate: "",
      dueDate: "",
      filter: {
        roCode: "",
        customer: "",
        invCode: "",
        licensePlate: "",
        userBranchId: "",
      },
      formCreate: {
        branch: "",
        cus: "",
        billingDate: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "billingCode",
          sortable: true,
          label: "เลขที่วางบิล",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "paymentDate",
          sortable: true,
          label: "วันที่สร้าง",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
          class: "text-end",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
    };
  },
  validations: {
    formCreate: {
      branch: {
        required,
      },
      cus: {
        required,
      },
      billingDate: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    // this.getDataCustomer();
  },
  created() {
    // this.select()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true; //skeleton true
      var userId = "100001993696138";
      // var page_id = "107320020619190";
      var userAccessToken =
        "EAAEeWGfJWLwBAF6Ak4EOx5TK6wpyA1W7wKDvafFLESOSj5vV3y6DuwjQJZCb2Rj6mDI2QbnnZAeRK7JoYAhkZA5GuCBVqi66cyVWz3oCVKFfmmXfzL1FHUkUimQKZBeIHALFkJCUxoyIr3DbLPXm7BZCbCFoXVvm2LsGthiJ3u44flKnpcYZBR";
      useNetw
        // .get(`https://graph.facebook.com/${userId}/accounts?access_token=${userAccessToken}`, {
  //       .get(
  //         `https://graph.facebook.com/${page_id}?
  // fields=access_token&
  // access_token=${userAccessToken}`,
          // {
            .get(`https://graph.facebook.com/${userId}/accounts?access_token=${userAccessToken}`, {
            data: {
              // qnId: rowData,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton false
        });
    },
  },
  middleware: "authentication",
};
</script>
<!-- <script>
var chatbox = document.getElementById("fb-customer-chat");
chatbox.setAttribute("page_id", "107320020619190");
chatbox.setAttribute("attribution", "biz_inbox");
</script> -->

<!-- Your SDK code -->
<!-- <script>
window.fbAsyncInit = function() {
  FB.init({
    xfbml: true,
    version: "v12.0",
  });
};

(function(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");
</script> -->
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="row">
    <div class="col-12">
      <b-overlay
        :spinner-variant="this.$overlayVariant"
        :spinner-type="this.$overlayType"
        :rounded="this.$overlayRounded"
      >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row">
                  <!-- Messenger ปลั๊กอินแชท Code -->
                  <!-- <div id="fb-root"></div> -->

                  <!-- Your ปลั๊กอินแชท code -->
                  <!-- <div id="fb-customer-chat" class="fb-customerchat"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
  <!-- </Layout> -->
</template>
